.MuiTable-root th {
  cursor: default !important;
}
.MuiTable-root td,
.MuiTable-root th > div {
  text-align: left !important;
}
.ignoreReportBtn {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ignoreReportBtn:hover {
  text-decoration: underline !important;
}
.reportedItemImg {
  width: 120px !important;
  height: 75px !important;
}
.reportedItemContent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.reportedItemDeleteBtn {
  color: #e91e63 !important;
  padding: 0 !important;
}
.reportedItemDeleteBtn:hover {
  text-decoration: underline !important;
}

.modalContainer .MuiDialog-paper {
  width: '100%';
  max-width: 750px;
}
.modalImgContainer {
  padding-bottom: 10px;
}
.modalImg {
  display: block;
  width: 300px;
  height: auto;
  margin: auto;
  object-fit: cover;
}

.modalActions {
  display: block !important;
}

.modalReportedByContainer {
  float: left;
}
.modalReportedByContainer::after {
  content: '';
  clear: both;
}
.modalReportedBy {
  font-weight: normal !important;
  font-size: 15px !important;
}

.modalActionsBtns {
  float: right;
}
.modalActionsBtns::after {
  content: '';
  clear: both;
}

.MuiList-root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.MuiList-root > a:last-child {
  margin-top: auto;
}

.ignoreReportBtn.ignore-all,
.reportedItemDeleteBtn.delete-all {
  padding-left: 0.6rem !important;
}
